.row-backlink {
    .backlink {
        background-color: $color-dunkelgrau;
        color: $color-weiss;
        font-weight: bold;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        display: inline-block;
        border: 1px solid $color-dunkelgrau;
    }

    .backlink:hover {
        background-color: $color-blau;
        color: $color-weiss;
        border: 1px solid $color-blau;
        text-decoration: none;
    }
}

form.searchform {
    .search-button {
        cursor: pointer;
        background: none;
        line-height: 1.625rem;

        background-color: $color-dunkelgrau;
        color: $color-weiss;
        font-weight: bold;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        display: inline-block;
        border: 1px solid $color-dunkelgrau;
    }

    @media (min-width: $break-lg) {
        .search-button {
            width: 116px;
            padding-top: 9px;
            padding-bottom: 9px;
        }
    }

    .search-button:hover {
        background-color: $color-blau;
        color: $color-weiss;
        border: 1px solid $color-blau;
        text-decoration: none;
    }

}

.switchSize button,
.switchColors button,
.switchContrast button {
    cursor: pointer;
}

.switchColors button:hover,
.switchColors button:focus,
.icon-Farbreduzierung:hover,
.icon-Farbreduzierung:focus {
    .Farbreduzierung-svg-st0 {
        fill: $color-blau;
    }
    .Farbreduzierung-svg-st1 {
        fill: $color-blau;
        stroke: $color-weiss;
    }
}

.switchContrast button:hover,
.switchContrast button:focus,
.icon-Kontrast:hover,
.icon-Kontrast:focus {
    .Kontrast-svg-st0 {
        fill: $color-blau;
    }
    .Kontrast-svg-st1 {
        fill: $color-blau;
        stroke: $color-weiss;
    }
    .Kontrast-svg-st2 {
        fill: $color-weiss;
    }
}

.switchSize button:hover,
.switchSize button:focus,
.icon-Schriftvergroesserung:hover,
.icon-Schriftvergroesserung:focus {
    .Schriftvergroesserung-svg-st0 {
        fill: $color-blau;
    }
    .Schriftvergroesserung-svg-st1 {
        fill: $color-weiss;
    }
    .Schriftvergroesserung-svg-st2 {
        fill: $color-weiss;
    }
}

.navbar-toggler-suche:hover,
.navbar-toggler-suche:focus,
.iconSuchen:hover,
.iconSuchen:focus {
    .suche-svg-st0 {
        fill: $color-blau !important;
    }
    .suche-svg-st1 {
        stroke: $color-weiss !important;
    }
    .suche-svg-st2 {
        stroke: $color-weiss !important;
    }
}

.iconSuchenAktiv {
    display: none;
    width: 57px;
    height: 57px;
}

.iconSuchen-Wrapper:hover,
.iconSuchen-Wrapper:focus {
    .iconSuchen {
        display: none;
    }
    .iconSuchenAktiv {
        display: inline-block;
    }
}

.header-spenden-button {
    background-color: $color-blau;
    //border-color: $color-blau;
    border-radius: 0;
    border: 0;
    height:57px;
    line-height: unset;
    padding: 12px;
    font-size: 1rem;
    vertical-align: bottom;

    @media (min-width: 520px) {
        font-size: 1rem;
        padding: 17px;
    }

    // @media (min-width: 992px) {
    //     font-size: 1rem;
    //     padding: 16px 12px;
    // }

    @media (min-width: 1200px) {
        font-size: 1.25rem;
        //padding: 12px;
    }

    &:hover {
        background-color: $color-dunkelgrau;
        border-color: $color-dunkelgrau;
    }

    &:focus {
        background-color: $color-dunkelgrau;
        border-color: $color-dunkelgrau;
        box-shadow: none;
    }

    .st0 {
        fill: $color-weiss;
    }
}
