h1, .h1 {
    font-weight: 800;
    font-size: 1.875rem;
    line-height: 2.5rem;
    @media (min-width: $break-md) {
        font-size: 2.5rem;
        line-height: 3.3125rem;
    }
    color: $color-weiss;
}
h1.hero, .h1.hero {
    font-size: 2.25rem;
    line-height: 2.5rem;
    @media (min-width: $break-md) {
        @include fontSize(50px);
        line-height: 4.0625rem;
    }
}

h2, .h2 {
    font-weight: 800;
    font-size: 1.4375rem;
    line-height: 1.875rem;
    @media (min-width: $break-md) {
        font-size: 1.875rem;
        line-height: 2.4375rem;
    }
    color: $color-blau;
}
h3, .h3 {
    font-weight: 800;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    // color: $color-blau;
}
h4, .h4, .title {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.6875rem;
}
.lead {
    font-size: 1.125rem;
    // line-height: 27px;
    line-height: 1.6875rem;

    color: $color-blau;
    padding-bottom: 50px;
}
.links {
    font-size: 1.125rem;
    line-height: 1.6875rem;
}
.blau-schmal {
    color: $color-blau;
    font-size: 1.3750rem;
    line-height: 1.6875rem;
}
.backlink {
    color: $color-blau;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    text-decoration: none;
}

.no-line-height {
    line-height: unset;
    margin-bottom: 0;
}
