.col-form {
    form {
        label {
            width: 100%;
            margin-bottom: 5px;
            font-weight: bold;
        }

        input, textarea {
            width: 100%;
            max-width: 100%;
            border: 1px solid $color-schwarz;
            background-color: $color-hellgrau;

            height: 30px;
            line-height: 1.625rem;
            padding-left: 5px;
            padding-right: 5px;
        }
        textarea {
            height: 200px;
        }
    }

    .submitbutton {
        background-color: $color-dunkelgrau;
        color: $color-weiss;
        font-size: 1.125rem;
        padding: 10px;
        padding-left: 30px;
        padding-right: 30px;
        border: 1px solid $color-dunkelgrau;
        font-weight: bold;
        height: 45px;
    }

    .freeform-form-has-errors {
        color: red;
    }
    .freeform-row .freeform-column .freeform-label {
        font-weight: normal;
    }
    .freeform-row .freeform-column .freeform-label.freeform-required:after {
        color: $color-dunkelgrau;
    }
    .submitbutton {
        width: 100%;
    }
}
