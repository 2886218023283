$color-blau:        rgba(0, 75, 135, 1);
$color-dunkelgrau:  rgba(63, 70, 76, 1);
$color-hellgrau:    rgba(231, 234, 239, 1);

$color-weiss:       rgba(255, 255, 255, 1);
$color-schwarz:     rgba(0, 0, 0, 1);

$color-fell-blond:   rgba(231,210,177, 1);
$color-fell-braun:   rgba(90, 60, 60, 1);
$color-fell-schwarz: rgba(63, 70, 76, 1);

:root {
    --color-blau: #{$color-blau};
    --color-dunkelgrau: #{$color-dunkelgrau};
    --color-hellgrau: #{$color-hellgrau};

    --color-weiss: #{$color-weiss};
    --color-schwarz: #{$color-schwarz};

    --color-fell-blond: #{$color-fell-blond};
    --color-fell-brau: #{$color-fell-braun};
    --color-fell-schwarz: #{$color-fell-schwarz};
}

$header-height-mobile: 102px;
$header-height-mobile-scrolled: 58px;
$header-height-tablet: 124px;
$header-height-tablet-scrolled: 58px;
$header-height-desktop: 170px;
$header-height-desktop-scrolled: 58px;

$break-xs: 400px;
$break-md: 750px;
$break-md: 768px;
$break-lg: 1024px;
$break-lg: 992px;
