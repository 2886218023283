.row-wuerfe,
.row-publikationen,
.row-mitarbeiter,
.row-fotos,
.row-fotos-inner {
    img {
        padding-bottom: 15px;
    }
    .wuerfe-untertitel {
        height: 105px;
    }
    a {
        text-decoration: none;
        p {
            color: $color-dunkelgrau;
        }
    }
    h2 {
        font-size: 1.125rem;
        line-height: 1.6875rem;
    }
    .geburtsdatum {
        font-size: 1rem;
        line-height: 1.6875rem;
    }
    @media (min-width: $break-md) {
        margin-left: 0;
        margin-right: 0;
        .col {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    @media (min-width: $break-lg) {
        .col {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.table-wuerfe {
    width: 100%;
    margin-bottom: 80px;
    tr {
        border-bottom: 1px solid $color-hellgrau;
    }
}

.row-backlink {
    height: 120px;
    padding-top: 20px;
}

.row-fotos-inner {
    img {
        padding-bottom: 20px;
    }
    @media (min-width: $break-md) {
        margin-left: 10px;
        margin-right: 10px;
        .col {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    @media (min-width: $break-lg) {
        .col {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    padding-bottom: 100px;
}

.row.row-fotos {
    .col {
        padding-bottom: 30px;
    }
    .col.last {
        padding-bottom: 60px;
    }
    figure img {
        padding-bottom: 0;
    }
}

.row-publikationen {
    h3 {
        line-height: 1.6875rem;
    }
    .col {
        padding-bottom: 40px;
    }

    .button-download {
        background-color: $color-dunkelgrau;
        color: $color-weiss;
        font-weight: bold;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        display: inline-block;
        border: 1px solid $color-dunkelgrau;
        width: 6.25rem;
        vertical-align: top;

        &:hover {
            color: $color-weiss;
            background-color: $color-blau;
        }
    }

    @media (min-width: $break-xs) {
        margin-right: 10px;
        margin-left: 10px;
        .col {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}
