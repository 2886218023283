.suche {
    background-color: $color-weiss;
    position: fixed;
    width: 100%;
    z-index: 102;
    top: $header-height-mobile;
    @media (min-width: $break-md) {
        top: $header-height-tablet;
    }
    @media (min-width: $break-lg) {
        top: $header-height-desktop;
    }
}

body.scrolled {
    .suche {
        top: $header-height-mobile-scrolled;
        @media (min-width: $break-md) {
            top: $header-height-tablet-scrolled;
        }
        @media (min-width: $break-lg) {
            top: $header-height-desktop-scrolled;
        }
    }
}

form.searchform {
    margin-top: 30px;
    margin-bottom: 85px;
    height: 200px;
    .search-input {
        background-color: $color-weiss;
        border: 1px solid $color-schwarz;
        height: 30px;
        line-height: 1.625rem;
        padding-left: 5px;
        padding-right: 5px;
        width: calc(100% - 120px);
        height: 38px;
        @media (min-width: $break-md) {
            width: 300px;
            height: auto;
            padding: 5px;
        }
        @media (min-width: $break-lg) {
            height: 46px;
        }
        margin-bottom: 15px;
    }

    @media (min-width: $break-md) {
        float: right;
        div {
            margin-right: -20px;
        }
    }

    @media (min-width: $break-lg) {
        .search-button {
            width: 116px;
            padding-top: 9px;
            padding-bottom: 9px;
        }
    }
}

form.searchform-site {
    margin-top: -50px;
    margin-bottom: 0;
    height: 100px;

    .search-input {
        background-color: $color-weiss;
        border: 1px solid $color-schwarz;
        height: 30px;
        line-height: 1.625rem;
        padding-left: 5px;
        padding-right: 5px;
        width: 100%;
        @media (min-width: $break-md) {
            width: 300px;
            height: auto;
            padding: 5px;
        }
        @media (min-width: $break-lg) {
            height: 46px;
        }
        margin-bottom: 15px;
    }

    float: left;
    div {
        margin-right: -20px;
    }

    @media (min-width: $break-lg) {
        .search-button {
            width: 116px;
            padding-top: 9px;
            padding-bottom: 9px;
        }
    }
}

.container-suche {
    a {
        text-decoration: none;
    }
    p {
        margin-bottom: 20px;
    }
}

.search-result-box {
    padding: 1rem;
    border-bottom: 1px solid $color-dunkelgrau;

    &:first-child {
        border-top: 1px solid $color-dunkelgrau;
    }

    &-breadcrumb {
        color: lighten($color-dunkelgrau, 10%);
        margin-bottom: 0.25rem;
        font-size: 0.9rem;

        .breadcrumb {
            background-color: transparent;
            padding: 0;
            margin-bottom: 0;
        }
    }
    &-title {
        margin-bottom: 0;
    }
    &-description {
        color: $color-dunkelgrau;
        font-size: 1rem;
        font-weight: normal;
    }

    &:hover {
        background-color: $color-blau;
        color: $white;

        .search-result-box {
            &-breadcrumb {
                color: $white;

                a {
                    color: $white;
                }
            }
            &-title {
                color: $white;
            }
            &-description {
                color: $white;
            }
        }
    }
}
.search-results-more {
    margin-top: 1rem;
}
.alert-blindenhundeschule {
    color: $color-dunkelgrau;
    background-color: $color-hellgrau;
    border-radius: 0;
}

.autocomplete-suggestions {
    margin-top: 10px;
}
.autocomplete-suggestions:hover {
    cursor: pointer;
}
