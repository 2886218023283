#row-mobile-header-right {
    .col-menu {
        button {
            span {
                height: 58px;
                width: 58px;
            }
            margin-top: calc(22px - 0.5rem);
            .navbar-toggler-text {
            }
            .icon {
            }
            .iconMenuBurger {
                height: 58px;
                width: 58px;
            }
        }
    }
    .col-suche {
        button {
            margin-top: calc(((102px - 58px) / 2) - 0.5rem);
        }
    }
}
body.scrolled #row-mobile-header-right {
    .col-menu, .col-suche {
        button {
            margin-top: -.5rem;
            bottom: 0;
        }
    }
}


.iconMenu {
    height: 57px;
    width: 116px;
}

.iconMenu:hover,
.iconMenu:focus {
    .Menu-svg-st0 {
        fill: $color-blau;
    }
    .Menu-svg-st1 {
        fill: $color-weiss;
        stroke: $color-weiss;
    }
    .Menu-svg-st2 {
        fill: $color-weiss;
    }
}

.iconMenu:hover,
.iconMenu:focus {
    .menu2st0 {
        fill: $color-blau;
    }
    .menu2st1 {
        fill: $color-weiss;
        stroke: $color-weiss;
    }
    .menu2st2 {
        fill: $color-weiss;
    }
}



#row-tablet-header-right-line-2,
#row-desktop-header-right-line-2 {

    @media (min-width: $break-lg) {
        .col-12 {
            height: 58px;
        }
    }

    .wrapper-navbar-toggler-tablet-burger {

        button {
            bottom: 0;
            margin-top: 0;
            font-size: 1rem;
        }

        //&:hover, &:active, &:focus {
        //    background-color: $color-blau;
        //    color: $color-weiss;
        //}
    }

}

@media (min-width: $break-md) {
    body.scrolled {
        #row-tablet-header-right-line-2,
        #row-desktop-header-right-line-2 {
            .wrapper-navbar-toggler-tablet-burger {
                top: 0;
            }
        }
    }

    #row-tablet-header-right-line-2 {
        margin-top: -4px;
        @media (min-width: $break-lg) {
            margin-top: -8px;
        }
    }
    body.scrolled {
        #row-tablet-header-right-line-2 {
            margin-top: -8px;
        }
    }

}



#row-desktop-header-main-navigation {
    .navbar-toggler.iconSuchen-Wrapper {
        right: 4px;
        height: 57px;
        margin-top: 0;

        //@media (min-width: $break-lg) {
        //    height: 59px;
        //}
    }
}

.main-navigation-header {

    a.nav-item-link-1.active {
        background-color: $color-blau;
        color: $color-weiss;
    }
    a.nav-item-link-1:not(.active) {
        background-color: $color-weiss;
        color: $color-dunkelgrau;
    }
    a.nav-item-link-1:hover {
        background-color: $color-blau;
        color: $color-weiss;
        text-decoration: none;
    }
    a.nav-item-link-1:focus {
        background-color: $color-blau;
        color: $color-weiss;
        text-decoration: none;
    }
    
    li.nav-item .navi-lvl2-wrapper {
        position: absolute;
        li.nav-item {
            background-color: $color-weiss !important;
            color: $color-dunkelgrau;
            float: none;
            a {
                padding-bottom: 12px;
                padding-top: 12px;
                background-color: $color-weiss !important;
                color: $color-dunkelgrau;
                @include fontSize(18px);
                width: 100%;
            }
        }
        li.nav-item:hover {
            background-color: $color-blau !important;
            color: $color-weiss;
            float: none;
            a {
                background-color: $color-blau !important;
                color: $color-weiss;
            }
        }
        li.nav-item {
            a:focus {
                background-color: $color-blau !important;
                color: $color-weiss;
            }
        }
    }

}


#accordionNavigation {
    .nav-list-level-2 {
        a:active {
            background-color: $color-blau;
            color: $color-weiss;
        }

        @media (min-width: $break-lg) {
            margin-top: 10px;
        }

    }
}

#Menu-Burger-svg {
    .Menu-Burger-svg-st0 {
        fill: $color-weiss;
    }
    .Menu-Burger-svg-st1 {
        fill: $color-dunkelgrau;
    }
}
#Menu-Burger-svg:hover,
#Menu-Burger-svg:focus {
    .Menu-Burger-svg-st0 {
        fill: $color-blau;
    }
    .Menu-Burger-svg-st1 {
        fill: $color-weiss;
    }
}

.premain {
    display: none;
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    background: white;
    z-index: 1000;
}
.premain.active {
    display: block;
}
