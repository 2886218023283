.teaser-component {
    a.card {
        border-radius: 0;
        border: none;
        
        .card-img, 
        .card-img-top {
            border-radius: 0;
            border: 1px solid $color-hellgrau;
        }

        &:hover {
            text-decoration: none;
        }

        p {
            margin-bottom: 0;
        }
    }
}