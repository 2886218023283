/*
content: 320
padding: lr je 40
total: 400

tablet:
content: 670
padding: lr je 40
total: 750

desktop:
content: 944
padding: lr je 40
total: 1024
*/

$grid-breakpoints: (
    xs: 0,
    sm: 400px,
    md: $break-md,
    lg: 1024px
);


$container-max-widths: (
    sm: 320px,
    md: 670px,
    lg: 944px
) !default;

//@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)){
//    .row > .col,
//    .row > [class*="col-"] {
//        padding-right: 115px;
//        padding-left: 115px;
//    }
//}

//@media (min-width: 576px){
//    .container {
//        max-width: none !important;
//    }
//}

//.row > .col,
//.row > [class*="col-"] {
//    padding-right: 20px;
//    padding-left: 20px;
//}

.row {
    margin-right: -10px;
    margin-left: -10px;
}

@media (min-width: map-get($grid-breakpoints, sm) ){
    .row > .col,
    .row > [class*="col-"] {
        padding-right: 40px;
        padding-left: 40px;
    }
    .row {
        margin-right: -20px;
        margin-left: -20px;
    }

    .row.row-slider > .col,
    .row.row-slider > [class*="col-"] {
        padding-right: 15px;
        padding-left: 15px;
    }
    .row.row-slider {
        margin-right: -10px;
        margin-left: -10px;
    }

}

//body:after {
//    content: "xs";
//    @media (min-width: map-get($grid-breakpoints, sm)) {
//        content: "sm";
//    }
//    @media (min-width: map-get($grid-breakpoints, md)) {
//        content: "md";
//    }
//    @media (min-width: map-get($grid-breakpoints, lg)) {
//        content: "lg";
//    }
//}
