.zeitzeugen-component {
    margin-top: 1rem;
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .component-item {
        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    .quote-icon {
        position: absolute;
        width: 1.5rem;

        &.quote-start {
            top: 0.5rem;
            left: 0rem;
        }
        &.quote-end {
            bottom: 0rem;
            right: -1rem;
            transform: rotate(180deg);
        }
    }
}
