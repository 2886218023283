.maintitle-component {
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;

    h1 {
        color: $color-blau;
        color: #004b87;
        font-size: 2rem;
        line-height: 2.25rem;
        font-weight: 400;
    }
}