.container-content-slider {
    padding-top: 0;
    margin-bottom: 50px;
    background-color: $color-dunkelgrau;
    .row-slider {
        height: 360px;
        margin-right: -15px;
        margin-left: -15px;
        @media (min-width: $break-lg) {
            height: 480px;
        }
    }
    .slider {
        background-color: $color-weiss;
        height: 360px;
        @media (min-width: $break-lg) {
            height: 480px;
        }
    }
    .slide {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 360px;
        @media (min-width: $break-lg) {
            height: 480px;
        }
    }
    .slick-arrow {
        background: none;
        border: none;
        color: $color-weiss;
        height: 100%;
        @media (min-width: $break-md) {
            height: 100%;
        }
        .chevron-left,
        .chevron-right {
            width: 20px;
            height: 32px;
        }
    }
    .next.slick-arrow {
        float: right;
    }
}
