.totop {
    position: fixed;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 50px;
    @media (min-width: $break-md) {
        bottom: 20px;
    }

    height: 40px;
    width: 40px;
    text-align: center;

    display: none;
    z-index: 1000;

    .back-to-top-hover {
        display: none;
    }

}

.totop:hover {
    .back-to-top {
        display: none;
    }
    .back-to-top-hover {
        display: inline;
    }
}

.totop.active {
    display: block;
}
