@use 'sass:math';
@import '~bootstrap/scss/bootstrap';
//@import '~@fancyapps/fancybox/dist/jquery.fancybox';
@import '~slick-carousel/slick/slick';
//@import '~slick-carousel/slick/slick-theme';
@import '~plyr/src/sass/plyr';
//import "~@fancyapps/ui/dist/fancybox/fancybox.css";

@function calculateRem($size) {
    $remSize: math.div($size, 16px);
    @return #{$remSize}rem;
}

@mixin fontSize($size) {
    font-size: $size; //Fallback in px
    font-size: calculateRem($size);
}
@mixin lineHeight($size) {
    line-height: $size; //Fallback in px
    line-height: calculateRem($size);
}

@import 'vars';
@import 'gutter';
@import 'typo';
@import 'skip';
@import 'general';

@import 'nav';
@import 'container-headerimage';
@import 'burgermenu';
@import 'container-navigation';
@import 'container-subnavigation';
@import 'container-suche';
@import 'container-content';
@import 'row-fotos';
@import 'container-slider';
@import 'container-video';
@import 'container-spenden';
@import 'container-newsliste';
@import 'container-teaser';
@import 'container-maintitle';
@import 'container-image-text';
@import 'container-jubilaeum-geschichte';
@import 'container-jubilaeum-zeitzeugen';
@import 'kontaktform';
@import 'footer';
@import 'iframe';

@import 'buttons';
@import 'totop';

@import 'shop';
@import 'nav2';
@import 'contrast-colors-fontsize';
@import 'focus';
@import 'cookie';

@import 'slick-theme';
