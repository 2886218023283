body.contrast {
    background: #fff !important;
    color: #000 !important;

    .container-navigation {
        a {
            color: #000 !important;
        }
    }

    .premain {
        background-color: #000;
    }

    .navbar {
        background-color: #000;

        .main-navigation-header {
            li {
                a.nav-item-link-1 {
                    &:not(.active) {
                        background-color: #000;
                        color: #fff;
                    }
                    &:hover,
                    &.active {
                        background-color: #fff;
                        color: #000;
                    }
                }

                .navi-lvl2-wrapper {
                    .nav-list-level-2 {
                        .nav-item-link-2 {
                            background-color: #000 !important;
                            color: #fff;

                            &:hover,
                            .active {
                                background-color: #fff !important;
                                color: #000;
                            }
                        }
                    }
                }
            }
        }

        .iconSuchenAktiv,
        .iconSuchen {
            rect.iitrtkjpfa-suche3st0,
            rect.tkxltwkpos-st0,
            rect.wjsecefcwy-st0 {
                fill: #000 !important;
            }
        }

        .wrapper-navbar-toggler-tablet-burger {
            &.active {
                svg.iconMenu {
                    rect.Menu-svg-st0 {
                        fill: #fff;
                    }
                    .Menu-svg-st1 {
                        stroke: #000;
                    }
                    .Menu-svg-st2 {
                        fill: #000;
                    }
                }
            }

            svg.iconMenu {
                &:hover {
                    rect.Menu-svg-st0 {
                        fill: #fff;
                    }
                    .Menu-svg-st1 {
                        stroke: #000;
                    }
                    .Menu-svg-st2 {
                        fill: #000;
                    }
                }
                rect.Menu-svg-st0 {
                    fill: #000;
                }
                .Menu-svg-st1 {
                    stroke: #fff;
                }
                .Menu-svg-st2 {
                    fill: #fff;
                }
            }
        }

        .navbar-toggler-menu {
            .iconMenu {
                &:hover {
                    rect.menu2st0 {
                        fill: #fff !important;
                    }
                    line {
                        stroke: #000;
                    }
                    path.menu2st2 {
                        fill: #000;
                    }
                }
                rect.menu2st0 {
                    fill: #000 !important;
                }
                line {
                    stroke: #fff;
                }
                path.menu2st2 {
                    fill: #fff;
                }
            }
        }

        .navbar-toggler-tablet-burger,
        .navbar-toggler-mobile-burger {
            &.collapsed {
                svg#Menu-Burger-svg {
                    rect.Menu-Burger-svg-st0 {
                        fill: #000;
                    }
                    rect.Menu-Burger-svg-st1 {
                        fill: #fff;
                    }
                }
            }

            svg#Menu-Burger-svg {
                &:hover {
                    rect.Menu-Burger-svg-st0 {
                        fill: #fff;
                    }
                    rect.Menu-Burger-svg-st1 {
                        fill: #000 !important;
                    }
                }
            }
        }

        .navbar-toggler-tablet-suche,
        .navbar-toggler-mobile-suche {
            &.collapsed {
                svg.iconSuchen {
                    rect,
                    path {
                        fill: #000 !important;
                    }
                    line,
                    circle {
                        stroke: #fff;
                    }
                }
            }

            &:hover,
            &:not(.collapsed) {
                svg.iconSuchen {
                    rect.suche-svg-st0 {
                        fill: #fff !important;
                    }
                    circle.suche-svg-st1,
                    line.suche-svg-st2 {
                        stroke: #000 !important;
                    }
                }
            }

            svg.iconSuchen {
                rect,
                path {
                    fill: #fff;
                }
                line,
                circle {
                    stroke: #000;
                }
            }
        }

        svg.icon-Kontrast,
        svg.icon-Farbreduzierung,
        svg.icon-Schriftvergroesserung,
        svg.iconSuchenAktiv {
            rect,
            path {
                fill: #000 !important;
            }

            path.Schriftvergroesserung-svg-st1,
            path.Schriftvergroesserung-svg-st2 {
                fill: #fff !important;
            }

            path.Farbreduzierung-svg-st1 {
                stroke: #fff;
            }

            line,
            circle {
                stroke: #fff;
            }
            circle.Kontrast-svg-st1 {
                fill: #fff;
            }

            &:hover {
                rect {
                    fill: #fff !important;
                }

                circle,
                line {
                    stroke: #000 !important;;
                }

                path {
                    fill: #000 !important;;
                }

                path.Farbreduzierung-svg-st1 {
                    stroke: #fff !important;
                }
            }
        }


        .nav-item-language-switch-desktop {
            li {
                a {
                    color: #fff;
                }
            }
        }

        .logolink {
            svg.logoDesktop,
            svg.logoTablet,
            svg.logoTabletScrolled {
                g {
                    path {
                        fill: #fff !important;
                    }
                }
            }
        }
    }

    .navbar .navbar-toggler-text {
        color: #000 !important;
    }

    .container-navigation nav.main-navigation a.nav-item-link-1:not(.collapsed) {
        background-color: #fff !important;
        color: #000 !important;
    }
    .wrapper-navbar-toggler-tablet-burger.active {
        //background-color: $color-blau !important;

        .navbar-toggler-text {
            color: $color-weiss !important;
        }
    }

    .container-subnavigation {
        background-color: #000 !important;
        color: #fff !important;
    }

    .container-content, .container-suche {

        .h2, h2 {
            color: #000 !important;
        }
        .lead {
            color: #000 !important;
        }
    }

    .container-suche {
        .search-result-box {
            border-bottom-color: #000;

            &:first-child {
                border-top-color: #000;
            }

            &-breadcrumb {
                .breadcrumb {
                    a {
                        color: #000;
                    }
                }
            }

            &:hover {
                background-color: #000;

                .search-result-box-breadcrumb {
                    a {
                        color: #fff;
                    }
                }

                .h3, h3 {
                    color: #fff !important;
                }
            }

            .h3, h3 {
                color: #000 !important;
            }
        }

        .search-results-more {
            a {
                color: #000 !important;
            }
        }
    }

    .zeitzeugen-component {
        .component-item {
            background-color: #fff !important;
        }
    }

    .component-image-text {
        h1, h2, h3, h4, h5, h6, 
        strong {
            color: #000;
        }
    }

    .container-content {
        ol.breadcrumb {
            .breadcrumb-item {
                color: #000;

                &:before {
                    color: #000;
                }

                a {
                    color: #000;
                }
            }
        }
    }

    .stoerer {
        svg {
            .fil0 {
                fill: #000;
            }
        }
    }

    .container-geschichte {
        a {
            color: #000 !important;
        }
        
        .timeline {
            li {
                &:before {
                    background-color: #000;
                }

                time {
                    color: #000;
                }
            }
        }
    }

    .teaser-component {
        .card-body {
            background-color: #fff !important;
            color: #000 !important;
        }
    }

    .maintitle-component h1 {
        color: #000 !important;
    }

    .container-spenden {
        background-color: #fff !important;
        color: #000 !important;
    }

    .container-spenden .container a,
    .container-spenden .container a h2,
    .container-spenden .container a p {
        color: #000 !important;
    }

    footer {
        background-color: #000 !important;
        color: #fff !important;
    }
}

body.colors {
    .container-headerimage,
    .container-subnavigation,
    .container-content,
    main,
    footer,
    header,
    .slide,
    img {
        filter: grayscale(100%) !important;
    }
}
