/* Navigation */
.navigation {
    position: fixed;
    width: 100%;
    z-index: 1002;
    z-index: 102;
    top: $header-height-mobile;

    //@media (max-width: 991px) {
    //    overflow: auto;
    //    background: white;
    //}

    @media (min-width: $break-md) {
        top: $header-height-tablet;
    }
    @media (min-width: $break-lg) {
        top: $header-height-desktop;
    }
}

body.scrolled {
    .navigation {
        top: $header-height-mobile-scrolled;

        @media (min-width: $break-md) {
            top: $header-height-tablet-scrolled;
        }
        @media (min-width: $break-lg) {
            top: $header-height-desktop-scrolled;
        }
    }
}

.container-navigation {
    background: $color-weiss;

    font-size: 1.125rem;
    height: 100vh;

    a {
        color: $color-dunkelgrau;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }

    nav.language-switch {
        padding-top: 40px;
        li {
            float: left;
            margin-right: 15px;
        }
        li.active {
            a {
                font-weight: bold;
            }
        }
    }

    nav.main-navigation {
        li {
            margin-top: 15px;
            margin-bottom: 0;
        }

        a.nav-item-link-1 {
            font-weight: 800;

            // mobile
            display: inline-block;
            width: calc(100% + 10px);
            padding: 5px;
            margin-left: -5px;
            margin-right: -5px;

        }
        a.nav-item-link-1:not(.collapsed):not(.shop-link) {
            background-color: $color-blau;
            color: $color-weiss;
        }
        a.nav-item-link-1.collapsed {
            background-color: $color-weiss;
            color: $color-dunkelgrau;
        }

        a.nav-item-link-1:hover {
            text-decoration: none;
            cursor: auto;
        }

        // full-size navi
        @media (min-width: $break-lg) {
            .nav-list-level-1 li {
                float: left;
                width: calc(1024px / 5);
            }
            .nav-list-level-2 {
                display: block;
            }
        }
    }

    @media (min-width: $break-lg) {
        .collapse:not(.show) {
            display: block;
        }
    }
}

.a11y-mobile {
    svg {
        width: 50px;
        height: 50px;
    }
}

.iconBurger {
    width: 50px;
    height: 37px;
    @media (min-width: $break-md) {
        width: 40px;
        height: 29px;
    }
}

.navbar-toggler-mobile-burger:not(.collapsed),
.navbar-toggler-tablet-burger:not(.collapsed) {
    .iconBurger {
        background-color: $color-blau;
        .burgerIcon-svg-st0 {
            stroke: $color-weiss;
        }
    }
}
.iconBurger:hover,
.iconBurger:focus {
    background-color: $color-blau;
    .burgerIcon-svg-st0 {
        stroke: $color-weiss;
    }
}

.navbar-toggler-mobile-suche:not(.collapsed),
.navbar-toggler-tablet-suche:not(.collapsed) {
    .suche-svg-st0 {
        fill: $color-blau;
    }
    .suche-svg-st1,
    .suche-svg-st2 {
        stroke: $color-weiss;
    }
}
.iconSuchen:hover,
.iconSuchen:focus {
    .suche-svg-st0 {
        fill: $color-blau;
    }
    .suche-svg-st1,
    .suche-svg-st2 {
        stroke: $color-weiss;
    }
}

@media (min-width: $break-md) {
    .wrapper-navbar-toggler-tablet-burger {
        height: 57px;
    }

    .jubilaeum .wrapper-navbar-toggler-tablet-burger {
        width: auto;
        position: static;
        height: 57px;
    }

    .wrapper-navbar-toggler-tablet-burger.active {
        // background-color: $color-blau;
        .navbar-toggler-text {
            color: $color-weiss !important;
        }
    }

    .navbar-top.navbar .navbar-toggler-menu {
        margin-top: 16px;
    }
}

body.scrolled {
    .navbar-top.navbar .navbar-toggler-menu {
        margin-top: 28px;
    }
}

.row-main-navigation-header {
    height: auto;
    margin-top: -11px;
    //margin-left: 60px;
    @media (min-width: $break-lg) {
        //margin-left: 140px;
    }
}

.main-navigation-header {
    li {
        background-color: $color-weiss;
        float: left;

        a {
            padding: 17px;
            display: inline-block;
            color: $color-dunkelgrau;
            //@include fontSize(20px);
            font-size: 1rem;

            @media (min-width: 992px) {
                font-size: 1rem;
            }

            @media (min-width: 1200px) {
                font-size: 1.25rem;
            }

            &.back-link {
                svg {
                    height: 1.2rem;
                    margin-right: .5rem;
                }
            }
        }
    }

    li:hover {
        a {
            background-color: $color-blau;
            color: $color-weiss;
            text-decoration: none;
        }
    }
}

body.scrolled .main-navigation-header,
body.scrolled .row-main-navigation-header {
    display: none !important;
}

@media (min-width: $break-lg) {
    .wrapper-navbar-toggler-tablet-burger {
        display: none;
    }
    .row-a11y-switches .navbar-toggler-tablet-suche {
        display: none;
    }
    body.scrolled .wrapper-navbar-toggler-tablet-burger {
        display: block;
    }
    body.scrolled .row-a11y-switches .navbar-toggler-tablet-suche {
        display: inline-block;
    }
}

.container-headerimage-hide {
    display: none;
}
@media (min-width: $break-lg) {
    .container-headerimage-hide {
        background: white;
        height: 450px;
        position: absolute;
        width: 100%;
        z-index: 100;
    }
}

@media (max-width: 991px) {
    .scroll-on-mobile {
        overflow: scroll;
    }
}


#row-desktop-header-right-line-2 {
    .header-spenden-button {
        display: none;
    }
}

body.scrolled #row-desktop-header-right-line-2 {
    .header-spenden-button {
        display: inline-block;
        padding-top: 13px;
        padding-bottom: 14px;
        font-size: 1.25rem;

        @media (min-width: 992px) {
            padding-top: 13px;
        }

        @media (min-width: 992px) {
            padding-top: 13px;
        }
    }
}

body.scrolled #row-tablet-header-right-line-2 {
    > div {
        margin-top: -1px;
    }
}