footer {
    background: $color-dunkelgrau;

    padding-top: 50px;
    padding-bottom: 80px;

    color: $color-weiss;

    a, a:hover {
        color: $color-hellgrau;
    }

    .logoFooter {
        width: 98px;
        height: 80px;
        display: block;
        margin-bottom: 30px;
        @media (min-width: $break-md) {
            float:right;
        }
    }

    .footerAdresse {
        padding-bottom: 30px;
        p {
            margin-bottom: 0;
        }
        a:hover {
            font-weight: bold;
            text-decoration: none;
        }
    }

    .servicenavigation {
        .nav-link {
            padding: 0;
        }
        .nav-link:hover {
            font-weight: bold;
        }
    }

    .footerSocialMedia {
        padding-bottom: 30px;

        @media (min-width: $break-md) {
            display: flex;
            flex-wrap: wrap;
            gap: 14px;
            row-gap: 30px;
        }

        .iconFacebookFooter,
        .iconYoutubeFooter,
        .iconLinkedinFooter,
        .iconInstagramFooter,
        .iconEmailFooter,
        .iconTelefonFooter {
            height: 27.61px;
            width: 40px;
            margin-right: 10px;
        }
        a{
            text-decoration: none;
        }
        #facebook-svg {
            .facebook-svg-st0 { fill: #FFFFFF; }
            .facebook-svg-st1 { fill: #3f464c; }
        }
        #facebook-svg:hover {
            .facebook-svg-st0 { fill: #004B86; }
            .facebook-svg-st1 { fill: #FFFFFF; }
        }
        #insta-svg {
            .instagram-svg-st0 { fill: #FFFFFF; }
            .instagram-svg-st1 { fill: #3f464c; }
        }
        #insta-svg:hover {
            .instagram-svg-st0 { fill: #004B86; }
            .instagram-svg-st1 { fill: #FFFFFF; }
        }
        #linkedin-svg {
            .linkedin-svg-st0 { fill: #FFFFFF; }
            .linkedin-svg-st1 { fill: #3f464c; }
        }
        #linkedin-svg:hover {
            .linkedin-svg-st0 { fill: #004B86; }
            .linkedin-svg-st1 { fill: #FFFFFF; }
        }
        #youtube-svg {
            .youtube-svg-st0 { fill: #FFFFFF; }
            .youtube-svg-st1 { fill: #3f464c; }
        }
        #youtube-svg:hover {
            .youtube-svg-st0 { fill: #004B86; }
            .youtube-svg-st1 { fill: #FFFFFF; }
        }
        #mail-svg {
            .mail-svg-st0 { fill: #FFFFFF; }
            .mail-svg-st1 { fill: #3f464c; }
        }
        #mail-svg:hover {
            .mail-svg-st0 { fill: #004B86; }
            .mail-svg-st1 { fill: #FFFFFF; }
        }
        #telefon-svg {
            .telefon-svg-st0 { fill: #FFFFFF; }
            .telefon-svg-st1 { fill: #3f464c; }
        }
        #telefon-svg:hover {
            .telefon-svg-st0 { fill: #004B86; }
            .telefon-svg-st1 { fill: #FFFFFF; }
        }
    }

    .footerSpendenkonto {
        padding-bottom: 30px;
        span {
            display: block;
            @media (min-width: $break-lg) {
                display: inline;
            }
        }
        a:hover {
            font-weight: bold;
            text-decoration: none;
        }
    }

    .footerMitgliedschaften {
        a {
            display: inline-block;
        }
        img {
            height: 100px;
            width: 100px;
            margin-right: 15px;
        }
    }

    .last,
    .last img {
        margin-right: 0;
    }
}
