.container-content {
    
    > .container > *:last-child {
        margin-bottom: 50px;
    }

    .title-h2 {
        height: 90px;
        background: red;
    }

    ol.breadcrumb {
        background-color: transparent;
        margin-top: 1rem;
        margin-bottom: 2rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 4rem;
        }

        .breadcrumb-item + .breadcrumb-item::before {
            content: '>';
            color: var(--color-blau);
        }

        .breadcrumb-item {
            color: var(--color-blau);
            font-weight: bold;
            font-size: 1.25rem;

            svg {
                height: 1.25rem;
                margin-top: -.2rem;
                margin-right: .25rem;
            }
        }
    }

    .content-text,
    .content {
        ul {
            padding: 15px;
            padding-bottom: 30px;
            margin: 0;
        }
    }

    .row-bild {
        margin-bottom: 30px;
        figcaption {
            margin-top: 5px;
        }
    }
    .row-downloads {
        margin-bottom: 30px;

        .nav-item {
            margin-bottom: 15px;

            .button-download {
                background-color: $color-dunkelgrau;
                color: $color-weiss;
                font-weight: bold;
                padding: 5px;
                padding-left: 10px;
                padding-right: 10px;
                display: inline-block;
                border: 1px solid $color-dunkelgrau;
                width: 6.25rem;
                vertical-align: top;
            }
            .button-download-long {
                width: calc(100% - 115px);
                border-top: 1px solid $color-dunkelgrau;
                border-bottom: 1px solid $color-dunkelgrau;
                margin-left: 10px;
                padding: 5px;
                display: inline-block;
                background-color: $color-weiss;
                color: $color-dunkelgrau;
                font-weight: bold;
                span {
                    font-weight: normal;
                }
            }
        }
        .nav-item:hover {
            background-color: $color-blau;

            .button-download {
                border: 1px solid $color-blau;
            }

            .button-download, .button-download-long {
                background-color: $color-blau;
                color: $color-weiss;
                border-top: 1px solid $color-blau;
                border-bottom: 1px solid $color-blau;
                text-decoration: none;
            }
        }
    }
}

main, .main {
    .cta-button {
        background-color: $color-dunkelgrau;
        color: $color-weiss;
        font-size: 1.125rem;
        padding: 10px;
        padding-left: 30px;
        padding-right: 30px;
        border: 1px solid $color-dunkelgrau;
        font-weight: bold;
        height: 45px;
        a {
            color: $color-weiss;
        }
    }

    .cta-button {
        padding-top: 9px;
        padding-bottom: 9px;
        padding-top: 8px;
        padding-bottom: 8px;

        margin-top: 15px;
        margin-bottom: 15px;

    }

    a.cta-button:hover {
        text-decoration: none;
    }
}
