.iframe {
    margin-top: 50px;
    line-height: 0;
}
.container-content-last {
    display: none;
}

.container-content-last + .container-spenden {
    margin-top: 0;
}
