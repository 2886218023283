a.skip-main {
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
}
a.skip-main:focus, a.skip-main:active {
    color: #fff;
    background-color: $color-blau;
    left: 0;
    top: 10px;
    width: auto;
    height: auto;
    overflow: auto;
    padding: 5px;
    text-align: center;
    z-index: 1020;
}
