header {
    box-shadow: 0px -5px 5px 5px $color-dunkelgrau;
}
@media (min-width: $break-lg) {
    .homepage.overflow-hidden {
        header.fixed-top {
            box-shadow: none;
        }
    }
}
.navbar-top.navbar {
    background: $color-weiss;
    z-index: 1010;

    height: $header-height-mobile;
    @media (min-width: $break-md) {
        height: $header-height-tablet;
        text-align: center;
    }
    @media (min-width: $break-lg) {
        height: $header-height-desktop;
    }
}
body.scrolled {
    .navbar-top.navbar {
        height: $header-height-mobile-scrolled;
        @media (min-width: $break-md) {
            height: $header-height-tablet-scrolled;
        }
        @media (min-width: $break-lg) {
            height: $header-height-desktop-scrolled;
        }
    }
}

// LOGO
.navbar-top.navbar {

    .container {
        height: 100%;
        .row {
            height: 100%;
            @media (min-width: $break-lg) {
                height: auto;
            }
        }
    }

    .logoTabletScrolled {
        display: none;
    }
    .logoTablet {
        width: 200px;
        height: 60px;
        position: absolute;
        left: 0px;
        top: 13px;
        left: auto;
        margin-left: -15px;

        @media (min-width: $break-md) {
            left: 0;
            margin-left: 40px;
            width: 276px;
            width: 298px;
            height: 90px;
        }
        @media (min-width: $break-lg) {
            display: none;
        }
    }

    @media (min-width: $break-md) {
        .logoTablet.logoTablet-fr {
            width: 302px;
        }
        .logoTablet.logoTablet-it {
            width: 312px;
        }
        .logoTablet.logoTablet-en {
            width: 336px;
        }
    }

    .logoDesktop {
        display: none;
        @media (min-width: $break-lg) {
            display: block;
            width: 432px;
            height: 83px;
            position: absolute;
            left: auto;
            margin-left: 0;
            top: 13px;

        }
    }
    @media (min-width: $break-lg) {
        .logoDesktop.logoDesktop-fr {
            width: 540px;
        }
        .logoDesktop.logoDesktop-it {
            width: 532px;
        }
        .logoDesktop.logoDesktop-en {
            width: 449px;
        }
    }

    .logoHeaderWrapperLink {
        .logoHeader {
            width: 76px;
            height: 62px;
            margin-bottom: 5px;
            @media (min-width: $break-md) {
                width: 111px;
                height: 90px;
            }
            @media (min-width: $break-lg) {
                width: 111px;
                height: 90px;
                margin-bottom: 8px;
            }
        }
    }
}
body.scrolled {
    .navbar-top.navbar {

        .logoTablet {
            display: none;
        }
        .logoTabletScrolled {
            display: block;
            width: 50px;
            height: 41px;
            position: absolute;

            left: auto;
            margin-left: -15px;
            top: 2px;

            @media (min-width: $break-md) {
                left: 0;
                margin-left: 40px;
            }
        }

        .logoHeaderWrapperLink {
            margin-top: 0px;
            @media (min-width: $break-md) {
                margin-top: 5px;
            }
            @media (min-width: $break-lg) {
                margin-top: 5px;
            }
            .logoHeader {
                width: 46px;
                height: 38px;
                margin-top: 0px;
                @media (min-width: $break-md) {
                    width: 52px;
                    height: 42px;
                }
                @media (min-width: $break-lg) {
                    width: 52px;
                    height: 42px;
                    margin-bottom: 8px;
                }
            }
        }
    }
}

// BURGER
.navbar-top.navbar {
    .col-menu {
        padding: 0;
        @media (min-width: $break-md) {
            margin-bottom: 18px;
        }
    }

    .navbar-toggler {
        position: relative;
        margin-top: 26px;

        @media (min-width: $break-md) {
            margin-top: 13px;
        }

        @media (min-width: $break-lg) {
            margin-top: 15px;
        }

        bottom: 0px;
        padding: 0;
        border: none;
        border-radius: 0;
        .navbar-toggler-text {
            display: none;
            @media (min-width: $break-md) {
                display: block;
                color: $color-dunkelgrau;
                font-size: 1.3750rem;
                position: absolute;
                top: 3px;
                right: 45px;
            }
        }
        .navbar-toggler-icon {
            width: 50px;
            height: 37px;
            @media (min-width: $break-md) {
                width: 40px;
                height: 29px;
            }
            img {
                width: 50px;
                height: 37px;
                @media (min-width: $break-md) {
                    width: 40px;
                    height: 29px;
                }
            }
        }
    }

    .navbar-toggler-menu {

        @media (min-width: $break-md) {
            margin-top: 30px;
        }

    }
}
body.scrolled {

    .row-a11y-switches-1 {
        display: none !important;
    }

    .navbar-top.navbar {
        .col-menu {
            .navbar-toggler {
                margin-top: 6px;
            }
        }
        .logoDesktop {
            display: none;
        }

        .navbar-toggler {
            bottom: 1px;
        }
        @media (min-width: $break-md) {
            .navbar-toggler-text {
                top: -10px;
            }
            .navbar-toggler-icon {
                margin-top: -20px;
            }
        }

    }
}


// *** START SUCHE ***
.navbar-top.navbar {
    .col-suche {
        padding: 0;
        .navbar-toggler {
            margin-top: 18px;
        }
    }
    .iconSuchen {
        width: 57px;
        height: 57px;
    }
}
body.scrolled {
    .navbar-top.navbar {
        .col-suche .navbar-toggler {
            margin-top: 0px;
        }
    }
    @media (min-width: $break-md) {
        .navbar-toggler.navbar-toggler-suche {
            margin-top: 1px;
        }
    }
}
// *** ENDE SUCHE ***


// CLAIMS
.navbar-top.navbar {

    .col-claim {
        flex: 1;
        display: flex;                 // create nested flex container
        flex-wrap: wrap;               // enable flex items to wrap

        margin-bottom: 18px;
        * { align-self: flex-end; }

        padding-right: 0;

    }

    .claimLang, .claimKurz {
        display: none;
    }
    @media (min-width: $break-md) {
        .claimKurz {
            display: block;
            height: 71px;
            width: 160px;
            margin-top: 30px;
        }
    }
    @media (min-width: $break-lg) {
        .claimKurz {
            display: none;
        }
        .claimLang {
            display: block;
            height: 65px;
            width: 303px;
            margin-top: 30px;
            margin-top: 50px;
        }
    }
}
@media (min-width: $break-md) {
    body.scrolled {
        .navbar-top.navbar {
            .claimLang, .claimKurz {
                display: none;
            }
        }
    }
}

@media (min-width: $break-md) {
    .navbar-top.navbar .container .row-a11y-switches {
        margin-top: -8px;
        /* height: 58px; */
        height: auto;
        div {
            padding: 0;
        }
    }
    .navbar-top.navbar .container .row-menu-search {

    }
}
@media (min-width: $break-lg) {
    .navbar-top.navbar .container .row-a11y-switches {
        height: 58px;
    }
}
.icon-Farbreduzierung,
.icon-Kontrast,
.icon-Schriftvergroesserung {
    height: 58px;
    width: 58px;
}

.nav-item-language-switch-desktop {
    margin-top: 18px;
    li {
        float: left;
        margin-right: 15px;
        a {
            color: $color-dunkelgrau;
        }
    }
    li.active {
        a {
            font-weight: bold;
        }
    }
}

.logolink:focus {
    outline: none;

    svg {
        outline: 1px dotted #666;
        outline: -webkit-focus-ring-color auto 5px !important
    }
}
