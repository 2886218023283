.container-spenden {
    margin-top: 50px;

    background-color: $color-hellgrau;
    height: 115px;

    @media (min-width: $break-md) {
    }

    .container {
        height: 100%;
        .row, .col-12 {
            height: 100%;
        }
        .col-12 {
            display: flex;
            align-items: center;
        }

        a, a h2, a p {
            color: $color-dunkelgrau;
        }
        p {
            margin-bottom: 0;
        }

        .iconSpenden {

            margin-right: 10px;
            margin-bottom: 15px;
            height: 60px;
            width: 74px;

            @media (min-width: 470px) {
                margin-bottom: 0px;
                height: 55px;
                width: 68px;
            }

            .st0 {
                fill: $color-dunkelgrau;
            }
        }
        h2, .h2 {
            color: $color-dunkelgrau;
            font-size: 1.875rem;
            line-height: 1.5625rem;
        }
        p {
            font-size: 1.125rem;
            line-height: 1.5625rem;
        }

    }

    .iconSpendenWrapper {
    }
    .textSpendenWrapper {
    }
}

.iframe-payrexx {
    border: 0;
    margin-bottom: 50px;
}

.homepage .container-spenden {
    margin-top: 0;
}



.wrapper-trauerspende {
    label {
        width: 100%;
        margin-bottom: 0;
    }
    .w-full {
        width: 50%;
        min-width: 200px;
    }
}
