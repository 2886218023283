.container-geschichte {
    --primary-color: #{$color-blau};

    ol {
        margin-bottom: 0;
        padding-left: 0;
        list-style-type: none;
    }

    .links {
        position: sticky;
        top: 5rem;
        align-self: flex-start;

        li {
            font-weight: bold;
        }
    }

    .timeline {
        padding-right: 55px;

        @include media-breakpoint-up(lg) {
            padding-right: 20px;
        }

        li {
            --year-to-line-distance: 3.5rem;
            --box-to-line-distance: 4rem;

            position: relative;
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
            //width: .25rem;
            //margin: var(--year-to-line-distance) auto calc(var(--year-to-line-distance) + 2rem);
            //padding-top: 50px;
            //background-color: var(--primary-color);

            &::before {
                content: '';
                position: absolute;
                top: var(--year-to-line-distance);
                right: -1.5rem;
                width: .25rem;
                height: calc(100% - var(--year-to-line-distance));
                background-color: var(--primary-color);

                @include media-breakpoint-up(sm) {
                    right: -.5rem;
                }

                @include media-breakpoint-up(lg) {
                    right: auto;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            time {
                position: absolute;
                //top: calc(var(--year-to-line-distance) * -1);
                top: 0;
                right: -1.5rem;
                color: var(--primary-color);
                z-index: 1;
                font-weight: bold;

                @include media-breakpoint-up(sm) {
                    right: -.5rem;
                }

                @include media-breakpoint-up(lg) {
                    right: auto;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            .text {
                //position: relative;
                //bottom: 0;
                //width: calc(50% - 3rem);
                //padding: 15px;
                //background: #F45B69;

                //&::before {
                //    content: '';
                //    position: absolute;
                //    bottom: 7px;
                //    width: 0;
                //    height: 0;
                //    border-style: solid;
                //}

                h2 {
                    font-size: 1rem;
                    line-height: 1.6;
                    font-weight: bold;
                    margin-bottom: 0;
                }

                > *:last-child {
                    margin-bottom: 0;
                }
            }

            .media {
                margin-top: 1rem;

                @include media-breakpoint-up(lg) {
                    margin-top: 0;
                }

                figure {
                    margin-bottom: 0;
                }
            }

            &:nth-child(even) {
                .text {
                    @include media-breakpoint-up(lg) {
                        padding-left: var(--box-to-line-distance);
                    }
                    //left: 45px;

                    //&::before {
                    //    left: -15px;
                    //    border-width: 8px 16px 8px 0;
                    //    border-color: transparent #F45B69 transparent transparent;
                    //}
                }

                .media {
                    @include media-breakpoint-up(lg) {
                        padding-right: var(--box-to-line-distance);
                    }
                }
            }

            &:nth-child(odd) {
                .text {
                    @include media-breakpoint-up(lg) {
                        padding-right: var(--box-to-line-distance);
                    }
                    //left: -439px;

                    //&::before {
                    //    right: -15px;
                    //    border-width: 8px 0 8px 16px;
                    //    border-color: transparent transparent transparent #F45B69;
                    //}
                }

                .media {
                    @include media-breakpoint-up(lg) {
                        padding-left: var(--box-to-line-distance);
                    }
                }
            }
        }
    }
}
