.container-subnavigation {
    background: $color-blau;
    margin-bottom: 80px;
    padding-top: 35px;
    padding-bottom: 40px;

    @media (min-width: $break-md) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .container {
        min-height: 310px;
        @media (min-width: $break-md) {
            min-height: 260px;
        }

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

    }

    a, a:hover {
        color: $color-weiss;
        text-decoration: none;
        font-size: 1.125rem;
        line-height: 1.6875rem;
    }
    a:hover {
        text-decoration: underline;
    }

    .btn {
        color: $color-blau;

        &:hover {
            color: $color-blau;
            text-decoration: none;
        }
    }

    .titelLine1 {
        display: block;
        margin-bottom: 10px;
    }
    .titelLine2 {
        display: block;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        @include fontSize(16px);
        @include lineHeight(27px);
        @media (min-width: $break-md) {
            @include fontSize(18px);
        }
    }

    .row-title {
        flex: auto;
        .col-12 {
            min-height: 155px;
            @media (min-width: $break-md) {
                min-height: 130px;
            }
        }
    }

    .row-title:has(+.row-teaser) .col-12 {
        min-height: unset;
    }

    .nav {
        display: block;
        .nav-item.active {
            font-weight: bold;
        }
    }

}

body.suche .container-subnavigation,
body.homepage .container-subnavigation {
    min-height: calc(315px / 2);
    @media (min-width: $break-md) {
        min-height: calc(660px / 2);
    }
}
body.homepage .container-subnavigation {
    margin-bottom: 0;

    .container {
        justify-content: flex-start;
    }
}

.container-subnavigation-half {
    .container {
        min-height: 150px;
        @media (min-width: $break-md) {
            min-height: 130px;
        }
    }
}
