html {
    font-size: 16px;
    scroll-padding-top: 5rem;
}

body {
    font-family: 'Open Sans', sans-serif;
    background: $color-weiss;
    color: $color-dunkelgrau;
    font-weight: 400;
    line-height: 1.4375rem;
}
a, a:hover {
    color: $color-blau;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
a img {
    text-decoration: none;
}

img {
    max-width: 100%;
}

hr {
    border-top: 1px solid white;
}
.list-unstyled {
    list-style: none;
}

.farbfeld {
    width: 16px;
    height: 16px;
    display: inline-block;
    top: 2px;
    position: relative;
}
.farbe-blond {
    background: $color-fell-blond;
}
.farbe-braun {
    background: $color-fell-braun;
}
.farbe-schwarz {
    background: $color-fell-schwarz;
}

@media (min-width: $break-lg) {
    .pr20 {
        padding-right: 20px;
    }
    .pl20 {
        padding-left: 20px;
    }
}

body {
    margin-top: $header-height-mobile;
    @media (min-width: $break-md) {
        margin-top: $header-height-tablet;
    }
    @media (min-width: $break-lg) {
        margin-top: $header-height-desktop;
    }
}

.h70  { min-height: 70px; }
.h90  { min-height: 90px; }
.h120 { min-height: 120px; }

.flex-center {
    display: flex;
    justify-content: center;
}

.color-blau {
    color: $color-blau;
}
