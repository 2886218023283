.shopUebersicht {

    .row-warenkorb {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .shopline {
        border-top: 1px solid $color-hellgrau;
        border-bottom: 1px solid $color-dunkelgrau;
        margin-bottom: 30px;
        margin-bottom: 30px;
    }


    .addressBox {
        .row {
            margin-bottom: 15px;

        }

        label {
            width: 100%;
            margin-bottom: 0;
        }

        .w-full {
            width: 50%;
            min-width: 200px;
        }
    }

    main, .main {
        .preis {
            font-weight: bold;
        }

        button, .button, .shop-button {
            background-color: $color-dunkelgrau;
            color: $color-weiss;
            font-size: 1.125rem;
            padding: 10px;
            padding-left: 30px;
            padding-right: 30px;
            border: 1px solid $color-dunkelgrau;
            font-weight: bold;
            height: 45px;
            cursor: pointer;
            a {
                color: $color-weiss;
            }
        }

        button:hover,
        .shop-button:hover {
            background-color: $color-blau;
            border: 1px solid $color-blau;
            color: $color-weiss;
            a {
                color: $color-weiss;
            }
        }

        button .button {
            padding-top: 9px;
            padding-bottom: 9px;
            padding-top: 8px;
            padding-bottom: 8px;
        }

        a.button-weiter {
            width: max-content;
        }

        a.button-weiter:hover {
            text-decoration: none;
            background-color: $color-blau;
            border: 1px solid $color-blau;
            color: $color-weiss;
        }


        .lineItems-qty {
            text-align: center;
            padding: 5px;
            font-size: 1.125rem;
            height: 45px;
            border: 1px solid $color-dunkelgrau;
            font-family: system-ui;
            margin-right: 6px;
        }

        .lineItems-value {
            text-align: right;
            width: 80px;
            padding: 5px;
            font-size: 1.125rem;
            height: 45px;
            border: 1px solid $color-dunkelgrau;
            font-family: system-ui;
            margin-right: 6px;
        }

        .removeLineItemButton {
            @media (min-width: 1200px) {
                margin-left: 135px;
            }
            margin-top: 10px;
            background-color: $color-weiss;
            color: $color-dunkelgrau;
        }

        .row-warenkorb {

            button, .shop-button {
                width: 150px;
            }

            .lineItems-qty {
                width: 50px;
            }

            .removeLineItemButton {
                width: 150px;
            }
        }

    }

    .hidden {
        display: none;
    }


    #gateway {
        select {
            display: block;
            min-width: 200px;
            height: calc(2.25rem + 2px);
            padding: .375rem .75rem;
            font-size: 1rem;
            line-height: 1.5;
            color: $color-dunkelgrau;
            background-color: $color-weiss;
            border: 1px solid $color-dunkelgrau;
            border-radius: 0;
            border-bottom-left-radius: 0;
        }

    }

    .flash {
        display: block;
        color: red;
    }

    .formerror input {
        border: 1px solid red;
    }

    .qty-span {
        display: inline-block;
        width: 70px;
    }

    .value-span {
        display: inline-block;
        width: 40px;
    }
}


.shopUebersicht main .bs:hover {
    background-color: $color-blau;
    border: 1px solid $color-blau;
    color: $color-weiss;
    a {
        color: $color-weiss;
    }
}

.mwst-table {
    margin-top: 1.5rem;
    margin-left: auto;
}
