.container-headerimage {
    background-color: $color-hellgrau;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 340px;

    position: relative;

    @media (min-width: $break-md) {
        height: 450px;
        margin-top: 107px;
    }

    .stoerer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;

        a {
            display: inline-block;
        }

        svg {
            width: 6rem;
            height: auto;
        }
    }
}

.container-headerslider {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 340px;

    @media (min-width: $break-md) {
        height: 450px;
        margin-top: 107px;
    }

    .slide {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 340px;

        @media (min-width: $break-md) {
            height: 450px;
            margin-top: 0;
        }
    }
}
